import React, {FC} from "react";
import {Card} from "../components/Card";
import {faChevronLeft, faChevronRight, faCircleNotch, faDownload} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useApiCall} from "../api/api";

export const Reports: FC = () => {
  const [selectedYear, setSelectedYear] = React.useState(new Date())
  const {downloadHourReport} = useApiCall()
  const [downloads, setDownloads] = React.useState<{[ym: string]: boolean}>({})

  const getDownloadStateKey = (period: ExportPeriod) => `${period.year}-${period.period??'full'}`
  const downloadPeriod = async (period: ExportPeriod) => {
    setDownloads(dl =>({...dl, [getDownloadStateKey(period)]: true}))
    const blob = await downloadHourReport(period.year, period.period)
    const file = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = file
    a.download = `UrenRaport-${period.year}-periode-${period.period}.xlsx`
    a.click()
    URL.revokeObjectURL(file)
    setDownloads(dl =>({...dl, [getDownloadStateKey(period)]: false}))
  }

  return (
    <main className={"flex-1 overflow-y-scroll max-h-[calc(100vh-4rem)]"}>
      <div className={"mx-6 my-8"}>
        <div className={"max-w-screen-lg mx-auto"}>
          <div className={"flex justify-between items-center h-12 mb-2"}>
            <h1 className={"flex items-center text-2xl font-bold"}>Raportage</h1>
            <div className={'flex items-center border border-slate-200 rounded-lg px-2 py-1'}>
              <button
                className={"h-8 w-8 flex items-center justify-center text-slate-700 hover:bg-slate-100 rounded-full"}
                onClick={() => setSelectedYear(moment(selectedYear).subtract(1, 'year').toDate())}>
                <FontAwesomeIcon icon={faChevronLeft}/>
              </button>
              <span className={"w-24 font-bold text-center"}>
                {moment(selectedYear).format('YYYY')}
              </span>
              <button
                className={"h-8 w-8 flex items-center justify-center text-slate-700 hover:bg-slate-100 rounded-full"}
                onClick={() => setSelectedYear(moment(selectedYear).add(1, 'year').toDate())}>
                <FontAwesomeIcon icon={faChevronRight}/>
              </button>
            </div>
          </div>
          <Card title={'Uren per periode'}>
            <div className={'grid grid-cols-1 gap-y-2'}>
              {generatePeriodsForYear(selectedYear.getFullYear()).filter(y => y.period === null || moment(y.endDate).isBefore()).map((p,i) => {
                const loading = downloads[getDownloadStateKey(p)] ?? false
                return <div key={i}>
                  <button className={`${loading ? 'text-slate-600' : 'text-blue-700'} underline`} disabled={loading} onClick={() => downloadPeriod(p)}>
                    <FontAwesomeIcon icon={loading ? faCircleNotch : faDownload} spin={loading} className={'mr-2'} />
                    {p.period === null ? <>
                      Jaaroverzicht {p.year}
                    </> : <>
                      Periode {p.year}-{p.period} (weken: {p.weekNumbers.join(', ')})
                    </>}
                  </button>
                </div>
              })}
            </div>
          </Card>
        </div>
      </div>
    </main>
  );
}

type ExportPeriod = {
  year: number
  period: number|null
  weekNumbers: number[]
  startDate: Date
  endDate: Date
}
function generatePeriodsForYear(year: number): ExportPeriod[] {
  const periods: ExportPeriod[] = [{
    year: year,
    period: null,
    weekNumbers: Array.from({length: 52}, (_, i) => i+1),
    startDate: moment().year(year).week(1).startOf('week').toDate(),
    endDate: moment().year(year).week(52).endOf('week').toDate()
  }]
  for (let i = 0; i <= 12; i++) {
    // Periods starting at 0
    const weekNumbers = []
    for (let j = i*4; j <= (i*4+3) && j <= 52; j++) {
      if (i === 12 && moment().year(year).week(j+1).startOf('week').year() !== year) {
        continue
      }
      weekNumbers.push(j+1)
    }
    periods.push({
      year: year,
      period: i + 1,
      weekNumbers: weekNumbers,
      startDate: moment().year(year).week(weekNumbers[0]).startOf('week').toDate(),
      endDate: moment().year(year).week(weekNumbers[weekNumbers.length - 1]).endOf('week').toDate(),
    })
  }
  return periods
}